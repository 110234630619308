import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Button, Figure } from "react-bootstrap";

import ExecutiveLead from "../assets/images/lead.png";
import KoalendarLogo from "../assets/images/koalendar.svg";
import InitKoalendar from "./init-koalendar"; // Import the InitCalendly component

const ComponentName = () => {
    // const data = useStaticQuery(query);
    // const { allContentfulOurPortfolioBox: { nodes: vb } } = data;

    return (
        <div className="video-box">
            <div className="video-box-container"> 
                <p className="title">Ready to talk!</p>
                <p className="text">Set up a meeting through Koalendar</p>
                <div className="box-img">
                    <img src={ExecutiveLead} alt="executive lead" />
                    <div>
                        <h3 className="name">Lorik Mullaademi</h3>
                        <p className="position">Executive Lead</p>
                    </div>
                </div>
                {/* Use Koalendar for the Koalendar popup */}
                <InitKoalendar
                    buttonText="Set up a meeting"
                    className="primary-btn-s meet-sogody"
                    icon={KoalendarLogo}
                    
                />
            </div>
        </div>
    );
}

// export const query = graphql`
// {
//     allContentfulOurPortfolioBox(limit: 1, filter: { node_locale: { eq: "en-US" } }) {
//         nodes {
//             btnTitle
//             title
//             image {
//                 fluid(quality: 100) {
//                     src
//                 }
//             }
//         }
//     }
// }
// `

export default ComponentName;