import React from 'react'
import Layout from "../components/Layout"
import { Container} from 'react-bootstrap'
import CaseStudyBox from "../components/CaseStudyBox";
import VideoBox from "../components/VideoBox";
import BannerWork from '../components/BannerWork';
import ClientsSecond from '../components/ClientsSecond';
import CaseStudiesLoad from '../components/CaseStudiesLoad';
import { Seo } from '../components/Seo';
import metaImageOg from '../assets/images/work-og.png';
import { useLocation } from "@reach/router"

export default function() {
    const pathLocation = useLocation();
    const url = pathLocation.href;

    return (
    <Layout>
        <Seo 
            title="Success Stories | Partnerships | Sogody Work" 
            description="Find out how Sogody has helped companies across industries create the digital future they envision. Read our collection of success stories." 
            image={'https://sogody.com'+metaImageOg}
            url={url}
        />
        <div className="work"> 
        <BannerWork/>
        <Container>
        <div className="under-banner grid-container-1">
            {/* <div className=""> */}
                <div className="case-study-b"> <CaseStudyBox/></div>
                <div className="video-b"><VideoBox/></div>
                
            {/* </div> */}
                {/* <Row>
                    <Col xs="12" md="12" lg="8">
                    <CaseStudyBox/>
                    </Col>
                    <Col xs="12" md="12" lg="4">
                    <VideoBox/>
                    </Col>
                </Row> */}
        </div>
        <ClientsSecond/>
        {/* <CaseStudies /> */}
        </Container>
        <CaseStudiesLoad/>
        </div>
    </Layout>
    )
}

