import React from "react"
import { Row, Col, Container} from 'react-bootstrap';

export default function() {

    return (
        <div className="banner-work">  
            <Container> 
            <Row>
                <Col xs={12} md={12} lg={8} className="banner-title">
                    <h2 className="name">Helping the world's leading companies create the future of their experiences</h2>
                </Col>
                <Col xs={12} md={12} lg={4} className="banner-search">
                </Col>
            </Row> 
            </Container>
        </div>
    )
}



