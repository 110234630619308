import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import '../assets/style.css';
import {Button, Figure} from 'react-bootstrap';
export default function() {

    
    const data = useStaticQuery(query);
    const {allSanityCaseStudies:{
        nodes:cstudies
    }} = data

    return (
        
        <div className="case-study-box">  
        {cstudies.slice(0, 1).map((cs)=>{
                return <>
                <a href={`/work/${cs.csSlug}/`}> 
                <div className="cs-b" >
                    <div className="content">
                        <div className="pin-text">
                            <div className="pin-content"> 
                                <img className="pin-icon" style={{ }}  src={require('../assets/images/pin-angle-fill.svg').default} alt="Logo" /> 
                                <p className="pinned-text">Pinned by Sogody</p>
                            </div>
                            <p className="cs-title">{cs.csTitle}</p>
                            {cs.shortDescription && <p className="cs-subtitle">{cs.shortDescription[0].children[0].text}</p>}
                        </div>
                        <Button href={`/work/${cs.csSlug}/`} className="tertiary-btn-s"> Read more
                            <Figure className="read-more">
                                    <Figure.Image   src={require('../assets/images/next_service.png').default}  />  
                            </Figure>
                        </Button> 
                    </div>
                    <div className="image" style={{backgroundImage: `url(  ${cs.csImage.asset.url}  )`}}>  
                        {/* <img className="cs-img-1" src={cs.csImage.file.url} style={{maxWidth:"100%", maxHeight:"100%"}} alt="Case img"/> */}
                        {/* <Image className="cs-img" fluid={cs.csImage.fluid} alt="Case img"></Image> */}
                    </div> 
                </div>
                
                {/* <div className="grid-container">
                    <div className="image">  
                        <div className="both-images">
                                <Image className="cs-img" fluid={cs.csImage.fluid} alt="Case img"></Image>
                                <div className="cs-top-img" style={{display:"none"}}> 
                                    <div className="top-img" >  
                                        <Image  fluid={cs.csTopImage.fluid} alt="Case img"></Image> 
                                    </div>
                                </div>
                        </div>
                    </div> 
                    <div className="content">
                        <div className="pin-content"> 
                            <img className="pin-icon" style={{ }}  src={require('../assets/images/pin-angle-fill.svg')} alt="Logo" /> 
                            <p className="pinned-text">Pinned by Sogody</p>
                        </div>
                        <div className="left-text">
                            <p className="cs-title">{cs.csTitle}</p>
                            {cs.shortDescription && <p className="cs-subtitle">{cs.shortDescription.shortDescription}</p>}
                            <Button href={`/${cs.csSlug}`} className="tertiary-btn-s"> Read More
                                <Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png')}  />  
                                </Figure>
                            </Button> 
                        </div>
                    </div>
                </div> */}

                </a>
                </>
                })}

            {/* <Row style={{display: "flex",textAlign: "left"}} className="cs-row">
                {cstudies.map((cs)=>{
                return <>
                <Col xs={12} md="5" lg="5"  className="cs-column-2">
                        <div className="left-text">
                            <p className="cs-title">{cs.csTitle}</p>
                            <p className="cs-subtitle">{cs.shortDescription.shortDescription}</p>
                            <Button href={`/${cs.csSlug}`} className="tertiary-btn-s"> Read More
                                <Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png')}  />
                                </Figure>
                            </Button>
                        </div>
                    </Col> 
                <Col xs={12} md="7" lg="7" className="cs-column">
                        <Row>
                            <div className="both-images">
                                <Image className="cs-img" fluid={cs.csImage.fluid} alt="Case img"></Image>
                                <div className="cs-top-img"> 
                                    <div className="top-img"> 
                                        <Image  fluid={cs.csTopImage.fluid} alt="Case img"></Image>
                                    </div>
                                </div>
                            </div>
                        </Row>
                </Col>
                </>
                })}
            </Row>  */}


            </div>
            
    )
}

//   csImage{
//     fluid(quality: 100) {
//     ...GatsbyContentfulFluid
//     }
// }
export const query = graphql`
{
    allSanityCaseStudies(sort: {fields: _createdAt, order: DESC}) {
        nodes {
          id
          csSlug
          csTitle
          csTopImage {
            asset {
              url
            }
          }
          csImage {
            asset {
              url
            }
          }
          csBtn
          categoryTag {
            tag
          }
        }
    }
}
`


