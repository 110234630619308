import React,{useEffect, useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
    slice, concat, 
} from 'lodash';

import Image from "gatsby-image"
import '../assets/style.css';
import { Row, Col, Container, Card, Button, Figure} from 'react-bootstrap';

export default function() {
    const data= useStaticQuery(query);
    const {allSanityCaseStudies:{
        nodes:cstudies
    },allSanityCategory:{
        nodes:category
    } } = data

    const filterStudies = (list, selectedCategory) => {
        if (!selectedCategory) return list.slice(0,LENGTH); 

        return list.filter(cstudies=> 
                (cstudies.categoryTag || []).map(cT => cT.tag).includes(selectedCategory.tag)
        );
    }

    
    const handleCategory = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
        setList(slice(filterStudies(cstudies, selectedCategory), 0, LIMIT));
    };

    const LENGTH = cstudies.length;
    const DATA = cstudies;
    const LIMIT = 3;

    const [showMore,setShowMore] = useState(true);
    const [list,setList] = useState(slice(DATA, 0, LIMIT))
    const [index,setIndex] = useState(LIMIT);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    
    const loadMore = () =>{
        const newIndex =  index + 3;
        const newShowMore =  newIndex < (LENGTH);
        const newList = concat(list, slice(filterStudies(DATA, selectedCategory), index, newIndex));
        setIndex(newIndex);
        setList(newList);
        setShowMore(newShowMore);
    }

    return (
        <div className="case-studies">  
            <Container> 
                <Row className="scroll scroll1 overflow-auto category-row">
                    <Col md={"auto"}>
                    <button className="category-btn" id="category" onClick={() => handleCategory(undefined)}> 
                        <p className="cat-name">All</p> 
                    </button>
                    </Col>
                    {category.map((cat)=>{ 
                    return( <Col md={"auto"}>
                    <button className="category-btn" id="category" onClick={() => handleCategory(cat)}> 
                        <p className="cat-name">{cat.tag}</p> 
                    </button></Col> )})}
                </Row>
                <Row>
                    {list.map((cs)=>{
                    return <>
                    
                    <Col xs={12} md={6} lg={4} className="cs-column">
                        <a href={`/work/${cs.csSlug}/`}> 
                        <Card className={cs.csSlug}  key={cs.id} >
                            <Card.Body >
                                <div className="both-images">
                                    <img className="cs-img" src={cs.csImage.asset.url} alt="Case img" style={{maxWidth:"100%", maxHeight:"247px", objectFit:"cover"}} />
                                    <div className="cs-top-img" style={{display:"none"}}> 
                                        <div className="top-img"> 
                                            {/* <Image  fluid={cs.csTopImage.fluid} alt="Case img"></Image> */}
                                        </div>
                                    </div>
                                </div>
                                    {/* {(cs.categoryTag || []).map((cT, index) => ( 
                                        <p key={index} >{cT.tag}</p>
                                    ))} */}
                                <Card.Text className="cs-title"><h3> {cs.csTitle}</h3></Card.Text>
                                <Button href={`/work/${cs.csSlug}/`} className="tertiary-btn-s">{cs.csBtn}<Figure className="read-more">
                                            <Figure.Image   src={require('../assets/images/next_service.png').default}  />
                                        </Figure></Button>
                            </Card.Body>
                        </Card>
                        </a>
                    </Col>
                    </>
                    })}
                </Row> 
                <Row>
                    <Col className="load-more-col">
                        {showMore && <Button onClick={loadMore} className="loadmore-btn-s">More work
                            <Figure className="read-more">
                                <Figure.Image   src={require('../assets/images/load-more.svg').default}  />
                            </Figure>
                        </Button> }
                    </Col>
                </Row>
                {
                    DATA.map((link) => (<a href={`/work/${link.csSlug}/`} /> ))
                }
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allSanityCaseStudies(sort: {fields: _createdAt, order: DESC}) {
        nodes {
          id
          csSlug
          csTitle
          csTopImage {
            asset {
              url
            }
          }
          csImage {
            asset {
              url
            }
          }
          csBtn
          categoryTag {
            tag
          }
        }
    }
    allSanityCategory(sort: {fields: _createdAt}) {
        nodes {
            tag
        }
    }
}
`


